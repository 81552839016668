/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

@import 'assets/scss/theme';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

.slick-dots {
    bottom: -45px !important;

    li {
        button {
            &:before {
                content: "";
                background-color: #666276;
                position: absolute;
                display: inline-block !important;
                width: 1rem !important;
                height: 0.375rem !important;
                margin: 0 0.3rem !important;
                padding: 0 !important;
                transition: width .2s ease-in-out, background-color .2s ease-in-out;
                border: 0;
                border-radius: 50rem;
            }
        }
    }
}

.ui-toast {
    z-index: 9999 !important;
    /* Ensure the z-index is high enough to appear above other elements */
}